/* global meta */
/**
 * External dependencies
 */
import React, { Component, Suspense } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import 'react-tippy/dist/tippy.css';
import Cookies from 'js-cookie';
import Lightbox from 'react-image-lightbox';
import classnames from 'classnames';
import 'react-image-lightbox/style.css';

/**
 * Internal dependencies
 */
import { apiHelp, getUser, addInterceptor, getFacility, catchErrors } from 'utils/apiHelpers';
import { setFacility } from 'actions/facilityActions';
import { setUser, updateUnread, dismissNotification } from 'actions/userActions';
import Notice from 'components/Notice';
import Drawer from 'components/Drawer';
import Feedback from 'components/Feedback';
import Footer from 'components/Footer';
import Intercom from 'components/Intercom';
import Loading from 'components/Helpers/Loading';
import Partner from 'components/Partner';
import ProtectedRouteConsumer from 'components/ProtectedRouteConsumer';
import SideNav from 'components/SideNav';
import TermsOfService from 'components/TermsOfService';
import Topbar from 'components/Topbar';
import WideBanner from 'components/WideBanner';
import { openDrawer } from 'actions/drawerActions';
import { AppProvider } from 'components/Context/AppContext';

/**
 * Lazy load routed components.
 */
const BillingInfo = React.lazy(() => import('components/Forms/BillingInfo'));
const Calendar = React.lazy(() => import('components/TestingCalendar'));
const CalendarAdd = React.lazy(() => import('components/CalendarAdd'));
const CalendarEdit = React.lazy(() => import('components/CalendarEdit'));
const CalendarDelete = React.lazy(() => import('components/CalendarDelete'));
const CalendarForceExcuse = React.lazy(() => import('components/CalendarForceExcuse'));
const CalendarGenerate = React.lazy(() => import('components/CalendarGenerate'));
const Checks = React.lazy(() => import('components/Checks'));
const CheckInWindows = React.lazy(() => import('components/Settings/CheckInWindows'));
const Clients = React.lazy(() => import('components/Clients'));
const ClientsGet = React.lazy(() => import('components/Clients/ClientsGet'));
const ClientGroups = React.lazy(() => import('components/Forms/ClientGroups'));
const CommunicationsContainer = React.lazy(() => import('containers/Communications'));
const ClientLocations = React.lazy(() => import('containers/ClientLocations'));
const Dashboard = React.lazy(() => import('containers/Dashboard'));
const EditLocation = React.lazy(() => import('components/Settings/Locations/EditLocation'));
const Forms = React.lazy(() => import('components/Settings/Forms'));
const FormView = React.lazy(() => import('components/FormView'));
const FormsReporting = React.lazy(() => import('components/FormEvents/Reporting'));
const FormsArchive = React.lazy(() => import('components/FormEvents/Archive'));
const AssignForm = React.lazy(() => import('components/FormEvents/AssignForm'));
const FormsOverview = React.lazy(() => import('components/FormEvents/FormsOverview'));
const ClientInitiatedForms = React.lazy(() => import('components/FormEvents/ClientInitiatedForms'));
const FormsOpen = React.lazy(() => import('components/FormEvents/FormsOpen'));
const FormDetails = React.lazy(() => import('components/FormEvents/FormDetails'));
const CurfewAddEditEvent = React.lazy(() => import('components/CurfewEvents/AddEditEvent'));
const CommunityAddEditEvent = React.lazy(() => import('components/CommunityEvents/AddEditEvent'));
const BreathalyzerAddEditEvent = React.lazy(() => import('components/BreathalyzerEvents/AddEditEvent'));
const AppointmentAddEditEvent = React.lazy(() => import('components/AppointmentEvents/AddEditEvent'));
const AppointmentLog = React.lazy(() => import('components/AppointmentLog'));
const EventExplorer = React.lazy(() => import('components/Events/Explorer'));
const EventLog = React.lazy(() => import('components/EventLog'));
const EmailNotifications = React.lazy(() => import('components/Forms/EmailNotifications'));
const Locations = React.lazy(() => import('components/Settings/Locations'));
const MessagesTranscript = React.lazy(() => import('components/Forms/Transcript'));
const SettingsOther = React.lazy(() => import('components/Settings/Other'));
const SwitchAccount = React.lazy(() => import('components/SwitchAccount'));
const TestingProfiles = React.lazy(() => import('components/Settings/TestingProfiles'));
const BenchmarkOverview = React.lazy(() => import('components/Benchmarks/BenchmarkOverview'));
const TemplateList = React.lazy(() => import('components/Benchmarks/TemplateList'));
const AddEditTemplate = React.lazy(() => import('components/Benchmarks/AddEditTemplate'));
const AssignTemplate = React.lazy(() => import('components/Benchmarks/AssignTemplate'));
const BenchmarkReport = React.lazy(() => import('components/Benchmarks/Report'));
const TestTypes = React.lazy(() => import('components/Settings/TestTypes'));
const Payments = React.lazy(() => import('components/Settings/Payments'));
const Users = React.lazy(() => import('components/Users'));
const UsersAdd = React.lazy(() => import('components/UsersAdd'));
const UserGet = React.lazy(() => import('components/Forms/UserGet'));
const Violations = React.lazy(() => import('components/Violations'));
const ClearViolations = React.lazy(() => import('components/ClearViolations'));
const ReportDetailed = React.lazy(() => import('components/Reports'));
const ReportInactive = React.lazy(() => import('components/Reports/ReportInactive'));
const ReportTable = React.lazy(() => import('components/Reports/ReportTable'));
const IncentivesAndSanctionsReport = React.lazy(() => import('components/Reports/IncentivesAndSanctions'));
const DocumentList = React.lazy(() => import('components/DocumentLibrary/Documents'));
const DocumentAssignment = React.lazy(() => import('components/DocumentLibrary/Assignments'));
const UploadDocument = React.lazy(() => import('components/DocumentLibrary/UploadDocument'));
const CurriculumOverview = React.lazy(() => import('components/Curriculum/Overview'));
const TopicsList = React.lazy(() => import('components/Curriculum/Topic/List'));
const ResourcesList = React.lazy(() => import('components/Curriculum/Resource/List'));
const PacketsList = React.lazy(() => import('components/Curriculum/Packet/List'));
const AssignmentsList = React.lazy(() => import('components/Curriculum/Assignment/List'));
const AddAssignment = React.lazy(() => import('components/Curriculum/Assignment/AddAssignment'));
const SuperAdminDashboard = React.lazy(() => import('superadmin/dashboard'));
const AppLogins = React.lazy(() => import('components/Reports/AppLogins'));
const ClientsReport = React.lazy(() => import('components/Reports/Clients'));
const ClientAppSessionTimer = React.lazy(() => import('components/Reports/ClientAppSessionTimer'));
const ClientOverviewReport = React.lazy(() => import('components/Reports/ClientOverview'));
const ActionRequired = React.lazy(() => import('components/ActionRequired'));
const BulkMessageHistory = React.lazy(() => import('components/Reports/BulkMessageHistory/index'));

const updateFooterMargin = () => {
  const mainArea = document.querySelector('.main-area');
  const footer = document.querySelector('.footer');
  if (mainArea) {
    mainArea.classList.remove('has-action-bar');
  }
  if (footer) {
    footer.style.marginBottom = '';
  }
  const actionBar = document.querySelector('.action-bar');
  if (footer && actionBar) {
    footer.style.marginBottom = getComputedStyle(actionBar).height;
  }
};

class App extends Component {
  static propTypes = {
    children: PropTypes.node,
    facility: PropTypes.object.isRequired,
    features: PropTypes.object.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.object
    }),
    setFacility: PropTypes.func.isRequired,
    setUser: PropTypes.func.isRequired,
    updateUnread: PropTypes.func.isRequired,
    dismissNotification: PropTypes.func.isRequired,
    user: PropTypes.shape({
      notifications: PropTypes.shape({
        all_pages: PropTypes.object,
        dashboard: PropTypes.object
      })
    }).isRequired
  };

  state = {
    year: moment().format('YYYY'),
    isLightboxOpen: false,
    imgSrc: null,
    showBanner: false,
    showTermsOfService: false
  };

  hasAccessToMulti(features) {
    if (!Array.isArray(features)) return true;

    return features
      .map(feature => {
        if ('boolean' === typeof feature) return feature;
        else if ('function' === typeof feature) return feature();
        else if ('string' === typeof feature) return this.hasAccessTo(feature);
        return true;
      })
      .every(feature => !!feature);
  }

  hasAccessTo = feature => {
    const {
      user: { access_level },
      features
    } = this.props;
    const featureConfig = features?.[feature];
    if (!featureConfig) {
      switch (feature) {
        case 'superadmin':
          return access_level >= 20;
      }
    }
    if (!featureConfig?.status) {
      return false;
    }
    return featureConfig.access_level <= access_level;
  };

  updateDimensions = () => {
    var w = window,
      d = document,
      documentElement = d.documentElement,
      body = d.getElementsByTagName('body')[0],
      width = w.innerWidth || documentElement.clientWidth || body.clientWidth,
      height = w.innerHeight || documentElement.clientHeight || body.clientHeight;

    this.setState({ width, height });

    updateFooterMargin();
  };

  UNSAFE_componentWillMount() {
    this.updateDimensions();
  }

  switchAccount = facilityId => {
    apiHelp('facility/switch_account', { facility_id: facilityId })
      .then(res => {
        if (res.data.status === 'success') {
          this.props.history.push('/alerts');
        } else {
          // move them to the dashboard of their own facility
          this.props.history.push('/dashboard');
        }
      })
      .catch(catchErrors);
  };

  getUser = id => {
    const userToken = jwtDecode(Cookies.get('id_token'));
    meta.email = userToken.email;
    const param = !id ? { email: userToken.email } : { id };

    return (
      getUser(param)
        .then(response => {
          const {
            match: {
              params: { fid }
            }
          } = this.props;
          if (fid) {
            // check if we have a facility id in the url
            // this will specifically swap to the violations view
            if (response.data.facility_id != fid) {
              this.switchAccount(fid);
              throw 'redirecting...';
            }
          }
          return response;
        })
        // this interceptor will check to see that facilities match up
        .then(addInterceptor)
        .then(response => {
          if (response.data.user) {
            const { user } = response.data;
            this.props.setUser(user);

            return new Promise(resolve => {
              resolve(user);
            });
          } else {
            Cookies.remove('id_token');
            window.location.replace(
              'https://call2test.auth0.com/v2/logout?returnTo=' +
                window.location.protocol +
                '//' +
                window.location.host +
                '/login'
            );
          }
        })
        .catch(catchErrors)
    );
  };

  getFacility = () => {
    const {
      user: { id: user_id, access_level }
    } = this.props;
    getFacility()
      .then(res => {
        // We are gonna do some long polling
        if (res.data.facility.check_permissions.app.active) {
          this.longPoll = setInterval(() => {
            apiHelp('users/sync', { id: user_id })
              .then(res => {
                this.props.updateUnread(res.data.unread);
              })
              .catch(err => {
                clearInterval(this.longPoll);
                catchErrors(err);
              });
          }, 60 * 1000);
        }

        // Set up redux, eventually remove facility from context?
        this.props.setFacility(res.data.facility, res.data.features, access_level);
      })
      .catch(catchErrors);
  };

  handleTermsOfService = user => {
    this.setState({
      showTermsOfService: !user ? false : !user.tos_accepted?.accepted ?? false
    });

    return new Promise(resolve => {
      resolve(user);
    });
  };

  componentDidMount() {
    this.getUser()
      .then(this.handleTermsOfService)
      .then(this.getFacility);
    window.addEventListener('resize', debounce(this.updateDimensions, 200));

    this.maybeAddOffsetClass();
    updateFooterMargin();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.maybeAddOffsetClass();
      updateFooterMargin();
    }
  }

  componentWillUnmount() {
    clearInterval(this.longPoll);
    window.removeEventListener('resize', debounce(this.updateDimensions, 200));
  }

  /**
   * Adds a class to the body tag that allows us to shift the content towards left.
   * This is used for example when the Messages menu is clicked.
   */
  maybeAddOffsetClass = () => {
    if (
      location.pathname.match(
        /^\/clients|\/alerts|\/reports|\/settings|\/events|\/community|\/(curfew\/.*|log)|\/calendar|\/communications|\/forms|\/goals|\/documents|\/learning-lab/
      )
    ) {
      document.body.classList.add('is-sidepanel-open');
    } else {
      document.body.classList.remove('is-sidepanel-open');
    }
  };

  handleLightbox = (imgSrc, shouldRotate) =>
    this.setState({
      isLightboxOpen: !this.state.isLightboxOpen,
      imgSrc: imgSrc ? imgSrc : null,
      shouldRotate: shouldRotate
    });

  render() {
    if (!this.props.facility.facility_name) {
      return (
        <div
          style={{
            position: 'fixed',
            top: 'calc(50% - 4em)',
            left: 'calc(50% - 4em)',
            width: 'auto',
            height: 'auto'
          }}
        >
          <Loading />
        </div>
      );
    }

    const facilityName = `${this.props.facility.facility_name}${
      this.props.user.access_level > 10 ? ` (${this.props.facility.id})` : ''
    }`;

    return (
      <AppProvider
        value={{
          openDrawer: this.props.openDrawer,
          hasAccessTo: this.hasAccessTo,
          hasAccessToMulti: this.hasAccessToMulti,
          height: this.state.height,
          width: this.state.width,
          handleLightbox: this.handleLightbox,
          facility: this.props.facility,
          features: this.props.features,
          user: this.props.user,
          logout: this.props.logout
        }}
      >
        {this.state.showBanner && <WideBanner copy="" handleClickToAction={f => f} clickToActionLabel="" />}
        <div id="wrapper">
          <Topbar
            firstName={this.props.user.first_name}
            lastName={this.props.user.last_name}
            facilityName={facilityName}
            toggleMenu={this.toggleMenu}
            // Currently unused but it will eventually be used to display the avatars saved by users.
            avatarUrl={this.props.user.avatar_url}
          />
          <SideNav
            // So, we don't actually use pathname, but this just forces a refresh when the path changes
            pathname={this.props.location.pathname}
            calendar={+this.props.match.params.calendar || +this.props.facility.default_calendar || false}
          />
          <Drawer />
          <section role="main" className="main-area" data-equalizer-watch={true}>
            <Suspense fallback={<Loading />}>
              {!!this.props.user.notifications && (
                <Notice
                  pathname={this.props.location.pathname}
                  notifications={this.props.user.notifications}
                  dismiss={this.props.dismissNotification}
                />
              )}
              <Switch>
                <Route path="/iframed/:jwt/*" component={Partner} />
                <ProtectedRouteConsumer
                  exact
                  path="/dashboard"
                  title="Home"
                  component={Dashboard}
                  fallbackMessage="You do not currently have access to the Dashboard"
                  feature={['dashboard']}
                  redirect={!this.hasAccessTo('dashboard') ? '/clients' : '/dashboard'}
                  facilityName={facilityName}
                />
                <ProtectedRouteConsumer
                  exact
                  path="/clients"
                  title="Clients"
                  fallbackMessage="Your facility does not currently have access to Clients"
                  feature={['clients']}
                  component={Clients}
                />
                <ProtectedRouteConsumer
                  exact
                  path="/clients/add"
                  fallbackMessage="Your do not currently have access to Clients - Add Client"
                  title="Clients - Add Client"
                  feature={['clients', 'add_client']}
                  component={Clients}
                />
                <ProtectedRouteConsumer
                  exact
                  path="/clients/locations"
                  fallbackMessage="Your do not currently have access to Clients - Locations"
                  title="Clients - Locations"
                  feature={['clients', 'constant_location_tracking']}
                  component={ClientLocations}
                />
                <ProtectedRouteConsumer
                  exact
                  path="/clients/locations/:id"
                  fallbackMessage="Your do not currently have access to Clients - Locations"
                  title="Clients - Locations"
                  feature={['clients', 'constant_location_tracking']}
                  component={ClientLocations}
                />
                <ProtectedRouteConsumer
                  path="/clients/get/:id"
                  fallbackMessage="You do not currently have access to view this client profile"
                  title="Client - Profile"
                  feature={['clients']}
                  component={ClientsGet}
                />
                <ProtectedRouteConsumer
                  exact
                  path="/alerts"
                  title="Alerts"
                  feature={['violations']}
                  fallbackMessage="Your do not currently have access to Alerts"
                  component={Violations}
                />
                <ProtectedRouteConsumer
                  exact
                  path="/alerts/clear_alerts"
                  title="Clear Alerts"
                  feature={['violations', this.props.user.access_level >= 20]}
                  fallbackMessage="Your do not currently have access to Clear Alerts"
                  component={ClearViolations}
                />
                <ProtectedRouteConsumer
                  exact
                  path="/calendar/delete"
                  title="Calendar - Delete"
                  feature={['calendar', 'delete_calendar']}
                  fallbackMessage="You do not currently have access to Calendars"
                  component={CalendarDelete}
                />
                <ProtectedRouteConsumer
                  exact
                  path="/calendar"
                  title="Calendar"
                  feature={['calendar', 'daily_drug_check_ins']}
                  fallbackMessage="You do not currently have access to Calendar"
                  component={Calendar}
                  redirect={
                    !(this.hasAccessTo('calendar') && this.hasAccessTo('daily_drug_check_ins'))
                      ? '/clients'
                      : '/dashboard'
                  }
                />
                <ProtectedRouteConsumer
                  exact
                  path="/calendar/add"
                  title="Calendar - Add Calendar"
                  feature={['calendar', 'multi_calendar']}
                  fallbackMessage="You do not currently have access to Calendar - Add"
                  component={CalendarAdd}
                />
                <ProtectedRouteConsumer
                  exact
                  path="/calendar/excuse"
                  title="Calendar - Bulk Excuse"
                  feature={['calendar', 'bulk_excuse']}
                  fallbackMessage="You do not currently have access to Calendar - Bulk Excuse"
                  context="excuse"
                  component={CalendarForceExcuse}
                />
                <ProtectedRouteConsumer
                  exact
                  path="/calendar/force"
                  title="Calendar - Bulk Force"
                  feature={['calendar', 'bulk_force']}
                  fallbackMessage="You do not currently have access to Calendar - Bulk Excuse"
                  context="force"
                  component={CalendarForceExcuse}
                />
                <ProtectedRouteConsumer
                  exact
                  path="/calendar/generate"
                  title="Calendar - Generate Advanced Testing"
                  feature={['calendar', 'advance_test']}
                  fallbackMessage="You do not currently have access to Calendar - Advance Generate"
                  component={CalendarGenerate}
                />
                <ProtectedRouteConsumer
                  exact
                  path="/calendar/edit/:calendar"
                  title="Calendar - Edit"
                  feature={['calendar', 'edit_calendar']}
                  fallbackMessage="You do not currently have access to Calendar - Edit"
                  component={CalendarEdit}
                />
                <ProtectedRouteConsumer
                  exact
                  path="/calendar/:calendar?/:year?/:month?"
                  title="Calendar"
                  feature={['calendar']}
                  fallbackMessage="You do not currently have access to Calendars"
                  component={Calendar}
                />
                <ProtectedRouteConsumer
                  exact
                  path="/reports/detailed"
                  title="Reports - Detailed"
                  feature={['reports']}
                  fallbackMessage="You do not currently have access to view Detailed Reports"
                  component={ReportDetailed}
                />
                <ProtectedRouteConsumer
                  exact
                  path="/reports/clients"
                  title="Reports - Clients"
                  feature={['reports', 'client_csv_report']}
                  fallbackMessage="You do not currently have access to view Reports - Clients"
                  component={ClientsReport}
                />
                <ProtectedRouteConsumer
                  exact
                  path="/reports/client_overview"
                  title="Reports - Client Overview"
                  feature={['reports', 'case_management_report_client_overview']}
                  fallbackMessage="You do not currently have access to view Reports - Client Overview"
                  component={ClientOverviewReport}
                />
                <ProtectedRouteConsumer
                  exact
                  path="/reports/transcripts"
                  feature={['reports', 'access_all_client_messages']}
                  fallbackMessage="You do not currently have access to Reports - Transcripts"
                  title="Reports - Transcripts"
                  component={MessagesTranscript}
                  facilityReport
                />
                <ProtectedRouteConsumer
                  path="/reports/single/:date?/:calendar?"
                  title="Reports"
                  feature={['reports']}
                  fallbackMessage="You do not currently have access to Reports"
                  component={ReportTable}
                />
                <ProtectedRouteConsumer
                  path="/reports/inactive"
                  title="Reports - Inactive"
                  feature={['reports']}
                  fallbackMessage="You do not currently have access to Reports"
                  component={ReportInactive}
                />
                <ProtectedRouteConsumer
                  path="/reports/incentives_and_sanctions"
                  title="Reports - Incentives & Sanctions"
                  feature={['reports', 'incentives_and_sanctions']}
                  fallbackMessage="You do not currently have access to Reports"
                  component={IncentivesAndSanctionsReport}
                />
                <ProtectedRouteConsumer
                  exact
                  path="/reports/app_logins"
                  feature={['reports']}
                  fallbackMessage="You do not currently have access to Reports - App Logins"
                  title="Reports - App Logins"
                  component={AppLogins}
                />
                <ProtectedRouteConsumer
                  path="/reports/client_app_session_timer"
                  feature={['reports', 'client_app_session_timer']}
                  fallbackMessage="You do not currently have access to Reports - App Session Timer"
                  title="Reports - App Session Timer"
                  component={ClientAppSessionTimer}
                />
                <ProtectedRouteConsumer
                  exact
                  path="/reports/client_app_session_timer"
                  feature={['reports', 'client_app_session_timer']}
                  fallbackMessage="You do not currently have access to Reports - App Session Timer"
                  title="Reports - App Session Timer"
                  component={ClientAppSessionTimer}
                />
                <ProtectedRouteConsumer
                  exact
                  path="/reports/bulk_message_history"
                  feature={[this.props.user.access_level >= 7]}
                  fallbackMessage="You do not currently have access to Reports - Bulk Message History"
                  title="Reports - Bulk Message History"
                  component={BulkMessageHistory}
                />
                <ProtectedRouteConsumer
                  exact
                  path="/settings"
                  title="Settings - Email Notifications"
                  feature={['settings']}
                  fallbackMessage="You do not currently have access to Settings"
                  component={EmailNotifications}
                />
                <ProtectedRouteConsumer
                  exact
                  path="/settings/check_in_windows"
                  title="Settings - Check-In Windows"
                  feature={['settings', 'daily_drug_check_ins', !this.hasAccessTo('disable_check_in_requirement')]}
                  fallbackMessage="You do not currently have access to Settings - Check-In Windows"
                  component={CheckInWindows}
                />
                <ProtectedRouteConsumer
                  exact
                  path="/settings/client_groups"
                  title="Settings - Client Groups"
                  feature={['settings']}
                  fallbackMessage="You do not currently have access to Settings - Client Groups"
                  component={ClientGroups}
                />
                <ProtectedRouteConsumer
                  exact
                  path="/settings/test_types"
                  title="Settings - Test Types"
                  feature={['settings', 'daily_drug_check_ins']}
                  fallbackMessage="You do not currently have access to Settings - Test Types"
                  component={TestTypes}
                />
                <ProtectedRouteConsumer
                  path="/settings/testing_profiles/:id?"
                  title="Settings - Testing Profiles"
                  feature={['settings', 'daily_drug_check_ins']}
                  fallbackMessage="You do not currently have access to Settings - Testing Profiles"
                  component={TestingProfiles}
                />
                <ProtectedRouteConsumer
                  exact
                  path="/settings/locations"
                  title="Settings - Locations"
                  feature={['settings', 'schedule_management']}
                  fallbackMessage="You do not currently have access to Settings - Locations"
                  component={Locations}
                />
                <ProtectedRouteConsumer
                  exact
                  path="/settings/locations/edit/:location_id"
                  title="Settings - Edit Location"
                  feature={['settings', 'schedule_management']}
                  fallbackMessage="You do not currently have access to Settings - Edit Locations"
                  component={EditLocation}
                />
                <ProtectedRouteConsumer
                  exact
                  path="/settings/locations/add"
                  title="Settings - Add Location"
                  component={EditLocation}
                  isNewLocation={true}
                  feature={['settings', 'schedule_management']}
                  fallbackMessage="You do not currently have access to Settings - Add Locations"
                />
                <ProtectedRouteConsumer
                  exact
                  path="/settings/forms"
                  title="Settings - Forms"
                  component={Forms}
                  feature={['settings']}
                  fallbackMessage="You do not currently have access to Settings - Forms"
                />
                <ProtectedRouteConsumer
                  exact
                  path="/settings/forms/:formId"
                  title="Settings - View Form"
                  component={FormView}
                  feature={['settings']}
                  fallbackMessage="You do not currently have access to Settings - View Form"
                />
                <ProtectedRouteConsumer
                  exact
                  path="/settings/payment-programs"
                  title="Settings - Payment Programs"
                  component={Payments}
                  feature={['settings', 'self_pay', this.props.user.access_level >= 20]}
                  fallbackMessage="You do not currently have access to Settings - Payment Programs"
                />
                <ProtectedRouteConsumer
                  exact
                  path="/settings/other"
                  title="Settings - Other"
                  feature={[
                    (this.hasAccessTo('rand_max_tests_per_day') ||
                      this.hasAccessTo('custom_yes_message') ||
                      this.hasAccessTo('custom_no_message') ||
                      this.hasAccessTo('custom_late_message') ||
                      this.hasAccessTo('rand_reduced_days')) &&
                      this.hasAccessTo('daily_drug_check_ins')
                  ]}
                  fallbackMessage="You do not currently have access to Settings - Other"
                  component={SettingsOther}
                />
                <ProtectedRouteConsumer
                  path="/communications"
                  title="Communications"
                  feature={[this.props.user.access_level <= 10]}
                  fallbackMessage="You do not currently have access to send app messages to clients"
                  component={CommunicationsContainer}
                />
                <ProtectedRouteConsumer
                  exact
                  path="/users"
                  title="Users"
                  feature={['user_management']}
                  fallbackMessage="You do not have access to Users - User Management"
                  component={Users}
                />
                <ProtectedRouteConsumer
                  path="/users/add"
                  title="Users - Add User"
                  feature={['user_management']}
                  fallbackMessage="You do not have access to Users - Add User"
                  component={UsersAdd}
                />
                <ProtectedRouteConsumer
                  path="/users/get/:id"
                  fallbackMessage="You do not currently have access to this user profile"
                  title="Users - User Profile"
                  component={UserGet}
                  logout={this.props.logout}
                />
                <ProtectedRouteConsumer
                  path="/billing-info"
                  title="Billing Info"
                  feature={['edit_billing_contact']}
                  fallbackMessage="You do not have access to Billing"
                  component={BillingInfo}
                />
                <ProtectedRouteConsumer
                  path="/switch-account"
                  title="Switch Account"
                  feature={[this.props.user.accounts > 1 || this.props.user.access_level >= 20]}
                  component={SwitchAccount}
                  fallbackMessage="You do not currently have access to Switch Accounts"
                />
                <Route
                  exact
                  path="/checks/:year?/:month?/:day?"
                  title="Checks"
                  feature={true}
                  component={Checks}
                  fallbackMessage="You do not currently have access to Reports - Summary"
                />
                <ProtectedRouteConsumer
                  path="/feedback"
                  title="Feedback"
                  component={Feedback}
                  fallbackMessage="You do not currently have access to Feedback"
                />
                <ProtectedRouteConsumer
                  path="/forms/reporting"
                  title="Forms - Reporting"
                  feature={['schedule_management']}
                  fallbackMessage="You do not have access to Forms"
                  component={FormsReporting}
                />
                <ProtectedRouteConsumer
                  path="/forms/edit/:id?"
                  title="Edit an assigned form"
                  feature={['schedule_management']}
                  fallbackMessage="You do not have access to Forms"
                  component={AssignForm}
                />
                <ProtectedRouteConsumer
                  path="/forms/new"
                  title="Assign a form"
                  feature={['schedule_management']}
                  fallbackMessage="You do not have access to Forms"
                  component={AssignForm}
                />
                <ProtectedRouteConsumer
                  path="/forms/archive"
                  title="Forms - Form Responses"
                  feature={['schedule_management']}
                  fallbackMessage="You do not have access to Forms"
                  component={FormsArchive}
                />
                <ProtectedRouteConsumer
                  path="/forms/details/:id?"
                  title="Form Details"
                  feature={['schedule_management']}
                  fallbackMessage="You do not have access to Forms"
                  component={FormDetails}
                />
                <ProtectedRouteConsumer
                  path="/forms/open"
                  title="Forms - Open Forms"
                  feature={['schedule_management']}
                  fallbackMessage="You do not have access to Forms"
                  component={FormsOpen}
                />
                <ProtectedRouteConsumer
                  path="/forms/client-initiated"
                  title="Forms - Client-Scheduled Forms"
                  feature={['schedule_management']}
                  fallbackMessage="You do not have access to Forms"
                  component={ClientInitiatedForms}
                />
                <ProtectedRouteConsumer
                  path="/forms"
                  title="Forms - Overview"
                  feature={['schedule_management']}
                  fallbackMessage="You do not have access to Forms"
                  component={FormsOverview}
                />
                <ProtectedRouteConsumer
                  path="/events/curfew/add"
                  key="events_curfew_add"
                  title="Events - Add Curfew"
                  feature={['curfew_events']}
                  fallbackMessage="You do not have access to Curfew Events"
                  component={CurfewAddEditEvent}
                />
                <ProtectedRouteConsumer
                  path="/events/community/add"
                  key="events_community_add"
                  title="Events - Add Event"
                  feature={['community_events']}
                  fallbackMessage="You do not have access to Events"
                  component={CommunityAddEditEvent}
                />
                <ProtectedRouteConsumer
                  path="/events/appointment/add"
                  key="events_appointment_add"
                  title="Events - Add Appointment"
                  feature={['appointments']}
                  fallbackMessage="You do not have access to Appointments"
                  component={AppointmentAddEditEvent}
                />
                <ProtectedRouteConsumer
                  path="/events/breathalyzer/add"
                  key="events_breathalyzer_add"
                  title="Events - Add Event"
                  // feature={['curfew_events']}
                  feature={['breathalyzer']}
                  fallbackMessage="You do not have access to Events"
                  component={BreathalyzerAddEditEvent}
                />
                <ProtectedRouteConsumer
                  path="/events/explorer"
                  key="events_explorer"
                  title="Events - Event Explorer"
                  feature={['community_events']}
                  fallbackMessage="You do not have access to Event Explorer"
                  component={EventExplorer}
                />
                <ProtectedRouteConsumer
                  path="/events/curfew/edit/:id?"
                  title="Events - Edit Curfew"
                  feature={['curfew_events']}
                  fallbackMessage="You do not have access to Curfew Events"
                  component={CurfewAddEditEvent}
                />
                <ProtectedRouteConsumer
                  path="/events/breathalyzer/edit/:id?"
                  title="Events - Edit Breathalyzer Check-In"
                  feature={['breathalyzer']}
                  // feature={['breathalyzer']}
                  fallbackMessage="You do not have access to Breathalyzer Events"
                  component={BreathalyzerAddEditEvent}
                />
                <ProtectedRouteConsumer
                  path="/events/community/edit/:id?"
                  key="events_community_edit"
                  title="Events - Edit Event"
                  feature={['community_events']}
                  fallbackMessage="You do not have access to Events"
                  component={CommunityAddEditEvent}
                />
                <ProtectedRouteConsumer
                  path="/events/appointment/edit/:id?/:branching?"
                  key="events_appointment_edit"
                  title="Appointments - Edit"
                  feature={['appointments']}
                  fallbackMessage="You do not have access to Appointments"
                  component={AppointmentAddEditEvent}
                />
                <ProtectedRouteConsumer
                  path="/events/appointment/log"
                  key="events_appointment_log"
                  title="Appointments Log"
                  feature={['appointments']}
                  fallbackMessage="You do not have access to Appointments"
                  component={AppointmentLog}
                />
                <ProtectedRouteConsumer
                  path="/events/log/:year?/:month?"
                  title="Events - Event Log"
                  feature={['curfew_events', 'edit_delete_events_own_client']}
                  fallbackMessage="You do not have access to view the Event Log"
                  component={EventLog}
                />
                <ProtectedRouteConsumer
                  path="/goals/edit/:id?"
                  title="Goals - Edit"
                  feature={['benchmarks']}
                  fallbackMessage="You do not have access to Goals"
                  component={AddEditTemplate}
                />
                <ProtectedRouteConsumer
                  path="/goals/assign/:id?"
                  title="Goals - Assign Template"
                  feature={['benchmarks']}
                  fallbackMessage="You do not have access to Goals"
                  component={AssignTemplate}
                />
                <ProtectedRouteConsumer
                  path="/goals/add"
                  title="Goals - Add Template"
                  feature={['benchmarks']}
                  fallbackMessage="You do not have access to Goals"
                  component={AddEditTemplate}
                />
                <ProtectedRouteConsumer
                  path="/goals/reports"
                  title="Goals - Reports"
                  feature={['benchmarks']}
                  fallbackMessage="You do not have access to Goals"
                  component={BenchmarkReport}
                />
                <ProtectedRouteConsumer
                  path="/goals/templates"
                  title="Goals - Templates"
                  feature={['benchmarks']}
                  fallbackMessage="You do not have access to Goals"
                  component={TemplateList}
                />
                <ProtectedRouteConsumer
                  path="/goals"
                  title="Goals - Overview"
                  feature={['benchmarks']}
                  fallbackMessage="You do not have access to Goals"
                  component={BenchmarkOverview}
                />
                <ProtectedRouteConsumer
                  path="/documents/assign"
                  title="Document Library - Assignments"
                  feature={['document_library']}
                  fallbackMessage="You do not have access to the Document Library"
                  component={DocumentAssignment}
                />
                <ProtectedRouteConsumer
                  path="/documents/upload"
                  title="Upload Document"
                  feature={['document_library']}
                  fallbackMessage="You do not have access to the Document Library"
                  component={UploadDocument}
                />
                <ProtectedRouteConsumer
                  path="/documents"
                  title="Document Library - Documents"
                  feature={['document_library']}
                  fallbackMessage="You do not have access to the Document Library"
                  component={DocumentList}
                />
                <ProtectedRouteConsumer
                  path="/learning-lab/topics"
                  title="Learning Lab (LMS) - Topics"
                  component={TopicsList}
                  feature={['curriculum']}
                />
                <ProtectedRouteConsumer
                  path="/learning-lab/resources"
                  title="Learning Lab (LMS) - Resources"
                  component={ResourcesList}
                  feature={['curriculum']}
                />
                <ProtectedRouteConsumer
                  path="/learning-lab/packets"
                  title="Learning Lab (LMS) - Packets"
                  component={PacketsList}
                  feature={['curriculum']}
                />
                <ProtectedRouteConsumer
                  path="/learning-lab/assignments"
                  title="Learning Lab (LMS) - Assignments"
                  component={AssignmentsList}
                  feature={['curriculum']}
                />
                <ProtectedRouteConsumer
                  path="/learning-lab/assignment/new"
                  title="Learning Lab (LMS) - Add Assignment"
                  component={AddAssignment}
                  feature={['curriculum']}
                />
                <ProtectedRouteConsumer
                  path="/learning-lab"
                  title="Learning Lab (LMS) - Overview"
                  component={CurriculumOverview}
                  feature={['curriculum']}
                />
                <ProtectedRouteConsumer
                  path="/superadmin"
                  title="Super Admin"
                  component={SuperAdminDashboard}
                  feature={['superadmin']}
                />
                <ProtectedRouteConsumer
                  path="/action-required"
                  title="Action Required"
                  component={ActionRequired}
                  feature={['action_required_page_link']}
                />
                <Redirect to={!this.hasAccessTo('dashboard') ? '/clients' : '/dashboard'} />
              </Switch>
            </Suspense>
            <Footer year={this.state.year} />
          </section>
        </div>
        <TermsOfService
          userId={this.props.user.id}
          show={this.state.showTermsOfService}
          url="https://reconnect.io/terms-of-use"
        />
        {!!this.state.isLightboxOpen && (
          <Lightbox
            mainSrc={this.state.imgSrc}
            onCloseRequest={this.handleLightbox}
            wrapperClassName={classnames('lightbox-modal', {
              rotate: !!this.state.shouldRotate
            })}
          />
        )}
        {ENVIRONMENT === 'production' ? <Intercom user={this.props.user} facility={this.props.facility} /> : null}
      </AppProvider>
    );
  }
}

const ConnectedApp = withRouter(
  connect(
    ({ facility, features, user, router }) => ({
      facility,
      features,
      user,
      router
    }),
    {
      openDrawer,
      setUser,
      setFacility,
      updateUnread,
      dismissNotification
    }
  )(App)
);

export default ConnectedApp;
